@import "/src/colors.less";
@import "/src/mixins.less";
@import "/src/media-queries.less";

html {
  height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  height: 100%;
}

.d-none {
  display: none;
}

.zero-opacity {
  opacity: 0;
}

.no-scroll {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.uk-alert-success {
  background-color: #c0ffa3 !important;
  color: #2c6711 !important;
}

.uk-alert-danger {
  background-color: #ffabab !important;
  color: #e81d10 !important;
}

.uk-button-default {
  background-color: white !important;
}

.btn {
  .stylish-panel-gradient(@btn1);
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  text-decoration: none;
}

.btn:hover {
  .stylish-panel-gradient-inverse(@btn1);
  &:disabled {
    background-color: grey !important;
  }
}

.btn.selected {
  .stylish-panel-gradient(lighten(@btn1, 10%));
}

.btn.highlighted {
  .stylish-panel-gradient(lighten(@btn1, 15%));
  box-shadow: inset 0px 0px 5px #c1c1c1;
}

.btn:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.btn-secondary {
  .stylish-panel-gradient(@special1);
}

.btn-secondary:hover {
  .stylish-panel-gradient-inverse(@special1);
}

.btn-md {
  font-size: 1.2rem;
  min-height: 1.6rem;
  min-width: 170px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto;
}

.btn-lg {
  font-size: 1.4rem;
  min-height: 1.8rem;
  min-width: 170px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto;
}

.btn-xl {
  font-size: 1.7rem;
  min-height: 2.1rem;
  min-width: 200px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto;
}

.btn .btn-icon {
  width: 16px;
}

.toggle-btn {
  button.btn:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button.btn:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.uk-dotnav > li > a {
  border-color: @bgcolor5!important;
}

.uk-dotnav > li.uk-active > a {
  background-color: @bgcolor5!important;
}
