.stylish-panel(@color) {
  background-color: @color;
  box-shadow: inset 0px 1px 0px 0px lighten(@color, 20%);
  border: 1px solid darken(@color, 15%);
}

.stylish-panel-gradient(@color) {
  background-color: @color;
  background: linear-gradient(to bottom, @color 5%, lighten(@color, 10%) 100%);
  box-shadow: inset 0px 1px 0px 0px lighten(@color, 20%);
  border: 1px solid darken(@color, 15%);
}

.stylish-panel-gradient-inverse(@color) {
  background-color: lighten(@color, 10%);
  background: linear-gradient(to bottom, lighten(@color, 10%) 5%, @color 100%);
  box-shadow: inset 0px 1px 0px 0px lighten(@color, 20%);
  border: 1px solid darken(@color, 15%);
}
